<div
    class="bazis-control"
    [class.bazis-control--focused]="isFocused"
    [class.bazis-control--dirty]="ngControl.control.value"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            required: required$ | async,
            valueChanges: valueChanges$ | async,
            statusChanges: statusChanges$ | async
        } as data"
    >
        <label
            class="bazis-control__label"
            [class.bazis-h6]="hasTitleMajor"
            *ngIf="titleKey || title"
        >
            <bazis-interrogation
                *ngIf="tooltipSettings?.targetSide === 'labelStart' && tooltipKey"
                iconSize="xs"
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipOffset]="[-8, 4]"
                [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
            ></bazis-interrogation>
            {{ title || t(titleKey, titleParams) }}
            <span
                color="danger"
                *ngIf="data.required"
            >
                *
            </span>
            <bazis-interrogation
                *ngIf="tooltipSettings?.targetSide === 'labelEnd' && tooltipKey"
                iconSize="xs"
                [tooltipText]="t(tooltipKey, tooltipParams)"
                [tooltipOffset]="[-8, 4]"
                [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
            ></bazis-interrogation>
        </label>
        <label
            class="bazis-control__label"
            *ngIf="isEmptyLabel"
            >&nbsp;</label
        >

        <div
            class="bazis-control__field-group"
            [class.bazis-control__field-group--disabled]="field.disabled"
            [class.bazis-control__field-group--focused]="isFocused"
        >
            <div
                *ngIf="leftIcon"
                class="bazis-control__field-group__start"
            >
                <bazis-button
                    fill="clear"
                    #leftBtn
                    *ngIf="isLeftIconClickable && !data.readonly"
                    (click)="onLeftIconClick($event)"
                >
                    <bazis-icon
                        slot="icon-only"
                        [icon]="leftIcon"
                    ></bazis-icon>
                </bazis-button>
                <bazis-icon
                    *ngIf="!isLeftIconClickable"
                    [icon]="leftIcon"
                ></bazis-icon>
            </div>
            <ng-container *ngIf="tooltipSettings?.targetSide === 'left' && tooltipKey">
                <div
                    #tooltipLeft
                    class="bazis-control__field-group__start"
                >
                    <bazis-icon name="interrogation"></bazis-icon>
                </div>
                <bazis-tooltip
                    [reference]="tooltipLeft"
                    [eventType]="tooltipSettings?.eventType || 'hover'"
                    [placement]="tooltipSettings?.placement || 'bottom-start'"
                    >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                >
            </ng-container>
            <div
                class="bazis-control__field-wrapper"
                #fieldWrapRef
            >
                <input
                    *ngIf="!maskSettings && rows === 1"
                    [type]="type"
                    class="bazis-control__field"
                    [ngClass]="{
                        'bh-no-padding': withoutInnerPadding,
                        'bazis-control__field--erasable': hasEraser
                    }"
                    [placeholder]="
                        placeholder ? placeholder : placeholderKey ? t(placeholderKey) : ''
                    "
                    (focus)="focusField($event)"
                    (blur)="blurField($event)"
                    [formControl]="field"
                    [maxlength]="maxLength"
                />
                <textarea
                    *ngIf="!maskSettings && rows > 1"
                    class="bazis-control__field"
                    [placeholder]="
                        placeholder ? placeholder : placeholderKey ? t(placeholderKey) : ''
                    "
                    (focus)="focusField($event)"
                    (blur)="blurField($event)"
                    [formControl]="field"
                    [maxlength]="maxLength"
                ></textarea>
                <input
                    *ngIf="maskSettings"
                    [type]="type"
                    class="bazis-control__field"
                    [class.bh-no-padding]="withoutInnerPadding"
                    [placeholder]="
                        placeholder ? placeholder : placeholderKey ? t(placeholderKey) : ''
                    "
                    (focus)="focusField($event)"
                    (blur)="blurField($event)"
                    [formControl]="field"
                    [maxlength]="maxLength"
                    [mask]="maskSettings.mask"
                    [suffix]="maskSettings.suffix"
                    [prefix]="maskSettings.prefix"
                    [thousandSeparator]="maskSettings.thousandSeparator"
                    [decimalMarker]="maskSettings.decimalMarker"
                    [clearIfNotMatch]="maskSettings.clearIfNotMatch"
                    [showTemplate]="maskSettings.showTemplate"
                    [showMaskTyped]="maskSettings.showMaskTyped && isFocused"
                    [placeHolderCharacter]="maskSettings.placeHolderCharacter"
                    [dropSpecialCharacters]="maskSettings.dropSpecialCharacters"
                    [hiddenInput]="maskSettings.hiddenInput"
                    [shownMaskExpression]="maskSettings.shownMaskExpression"
                    [separatorLimit]="maskSettings.separatorLimit"
                    [allowNegativeNumbers]="maskSettings.allowNegativeNumbers"
                    [validation]="maskSettings.validation"
                    [specialCharacters]="maskSettings.specialCharacters"
                    [leadZeroDateTime]="maskSettings.leadZeroDateTime"
                    [apm]="maskSettings.apm"
                    [leadZero]="maskSettings.leadZero"
                    [keepCharacterPositions]="maskSettings.keepCharacterPositions"
                    [triggerOnMaskChange]="maskSettings.triggerOnMaskChange"
                    [inputTransformFn]="maskSettings.inputTransformFn"
                    [outputTransformFn]="maskSettings.outputTransformFn"
                />
            </div>
            <div
                *ngIf="unitKey || rightIcon || (ngControl.control.value && canEraserShow)"
                class="bazis-control__field-group__end"
            >
                <bazis-button
                    *ngIf="ngControl.control.value && canEraserShow"
                    fill="clear"
                    (click)="onClear($event)"
                    class="bazis-eraser"
                >
                    <bazis-icon
                        slot="icon-only"
                        name="cross-small"
                    ></bazis-icon>
                </bazis-button>
                <span
                    *ngIf="unitKey"
                    class="bazis-control__units"
                    [innerHTML]="t(unitKey)"
                ></span>
                <ng-container *ngIf="rightIcon">
                    <bazis-button
                        fill="clear"
                        #rightBtn
                        *ngIf="isRightIconClickable && !data.readonly"
                        (click)="onRightIconClick($event)"
                    >
                        <bazis-icon [icon]="rightIcon"></bazis-icon>
                    </bazis-button>
                    <bazis-icon
                        *ngIf="!isRightIconClickable"
                        [icon]="rightIcon"
                    ></bazis-icon>
                </ng-container>
            </div>
            <ng-container *ngIf="tooltipSettings?.targetSide === 'right' && tooltipKey">
                <div
                    #tooltipRight
                    class="bazis-control__field-group__end"
                >
                    <bazis-icon name="interrogation"></bazis-icon>
                </div>
                <bazis-tooltip
                    [reference]="tooltipRight"
                    [eventType]="tooltipSettings?.eventType || 'hover'"
                    [placement]="tooltipSettings?.placement || 'bottom-end'"
                    >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                >
            </ng-container>
        </div>

        <div class="bazis-control__error">
            <bazis-form-error
                [validationErrorMessages]="validationErrorMessages"
                [formControl]="field"
            ></bazis-form-error>
            <bazis-form-error
                *ngIf="field.status === 'VALID'"
                [validationErrorMessages]="validationErrorMessages"
                [formControl]="ngControl.control"
            ></bazis-form-error>

            <!-- для ошибок родительского контрола
            (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
            <ng-content></ng-content>
        </div>

        <div
            *ngIf="noteKey"
            class="bazis-control__note"
        >
            {{ t(noteKey, noteParams) }}
        </div>
    </ng-container>
</div>
