import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import {
    MaskSettings,
    TooltipSettings,
    ValidationErrorMessages,
} from '@bazis/form/models/form-element.types';
import { debounceTime, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EntityFormControl } from '@bazis/form/models/form.types';
import { Observable } from 'rxjs';
import { BazisFormService } from '@bazis/form/services/form.service';
import { latinizeStr } from '@bazis/utils';
import { initialConfig } from 'ngx-mask';

@UntilDestroy()
@Component({
    selector: 'bazis-input-default',
    templateUrl: './input-default.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisInputDefaultComponent implements ControlValueAccessor, OnChanges, OnInit {
    @Input() rows: number = 1;

    @Input() minLength: number = null;

    @Input() maxLength: number = null;

    // знак вопроса может быть рядом с полем
    @Input() tooltipKey: string = null;

    // заголовок поля
    @Input() title: string = null;

    // заголовок поля
    @Input() titleKey: string = null;

    // пустой label, для сохранения отступа, когда поля в ряд.
    @Input() isEmptyLabel: boolean = false;

    // возможно, нужен будет какой-то коммент под полем
    @Input() noteKey: string = null;

    // параметры для tooltip
    @Input() tooltipParams: any = null;

    // параметры для placeholderKey
    @Input() placeholderKey: string = '';

    // параметры для placeholder
    @Input() placeholder: string = '';

    // параметры для title
    @Input() titleParams: any = null;

    // параметры для note
    @Input() noteParams: any = null;

    // ед. измерения справа
    @Input() unitKey: string = null;

    // отображение метки поля выделенным стилем
    @Input() hasTitleMajor: boolean = false;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    // иконка, которую надо вывести слева
    @Input() leftIcon: string = null;

    // является ли иконка слева кликабельной
    @Input() isLeftIconClickable: boolean = null;

    // иконка, которую надо вывести справа
    @Input() rightIcon: string = null;

    // является ли иконка справа кликабельной
    @Input() isRightIconClickable: boolean = null;

    //Что выводить в readonly, если значения нет
    @Input() emptyValue: string = '';

    @Input() required$: Observable<boolean> = null;

    // настройки для маски (объединила в одном поле, чтобы "не размазывать по куче настроек")
    @Input() maskSettings: MaskSettings = null;

    // событие по клику на правой иконке
    @Output() rightIconClick = new EventEmitter();

    // событие по клику на левой иконке
    @Output() leftIconClick = new EventEmitter();

    // для всплытия состояния
    @Output() touched = new EventEmitter();

    // событие на blur()
    @Output() blured = new EventEmitter();

    // событие на focus()
    @Output() focused = new EventEmitter();

    // событие на стиралку
    @Output() erased = new EventEmitter();

    // для кастомных текстов ошибок
    @Input() validationErrorMessages: ValidationErrorMessages = {};

    // наличие стиралки для поля
    @Input() hasEraser: boolean = true;

    // наличие стиралки для поля
    @Input() formatter: Function = null;

    // convert all cyrillic symbols to latin (for vehicle gnum)
    @Input() forceLatin: boolean = false;

    @Input() type = 'text';

    // наличие внутренних отступов
    @Input() withoutInnerPadding: boolean = false;

    @Input() beforeWrite: (value: string | null) => string | null = null;

    @Input() processOnPaste: (value: string | null) => string | null; // TODO support

    @ViewChild('leftBtn') leftBtn: ElementRef;

    @ViewChild('rightBtn') rightBtn: ElementRef;

    @ViewChild('fieldWrapRef') fieldWrapRef: ElementRef;

    public field = new FormControl('');

    public onChange = (_: any) => {};

    public onTouched = () => {};

    isTouched = false;

    isFocused = false;

    canEraserShow = false;

    valueChanges$ = this.field.valueChanges.pipe(
        debounceTime(0),
        tap((value) => {
            if (this.formatter || this.forceLatin) {
                let newValue = this.forceLatin
                    ? latinizeStr(value.toUpperCase())
                    : this.formatter(value);
                if (value !== newValue) {
                    this.field.setValue(newValue);
                    return;
                }
            }
            this.onChange(value);
        }),
    );

    statusChanges$ = this.field.statusChanges.pipe(
        debounceTime(0),
        tap((status) => {
            if (status === 'INVALID') {
                this.ngControl.control.setErrors({
                    ...this.ngControl.control.errors,
                    componentError: true,
                });
            } else {
                const errors = this.ngControl.control.errors;
                if (errors) {
                    delete errors.componentError;
                    this.ngControl.control.setErrors({ ...errors });
                }
            }
        }),
    );

    constructor(@Self() public ngControl: NgControl, protected cdr: ChangeDetectorRef) {
        ngControl.valueAccessor = this;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.placeholder) {
            this.cdr.markForCheck();
        }
    }

    ngOnInit() {
        if (this.ngControl.control instanceof EntityFormControl) {
            BazisFormService.getPropertiesFromConfig(this, this.ngControl.control.$config);
        }

        if (this.maskSettings) {
            this.maskSettings = {
                ...initialConfig,
                ...this.maskSettings,
            };
        }

        this.canEraserShow = !this.field.disabled && this.hasEraser && this.rows === 1;
        this.writeValue(this.ngControl.control.value);
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.field.disable();
        } else {
            this.field.enable();
        }
    }

    public writeValue(value: any): void {
        if (!this.ngControl.control) return;

        if (this.beforeWrite) value = this.beforeWrite(value);
        this.field.setValue(value, { emitEvent: false });
    }

    public markAsTouched() {
        if (!this.isTouched) {
            this.onTouched();
            this.isTouched = true;
            this.touched.emit(true);
        }
    }

    onLeftIconClick(e) {
        this.markAsTouched();
        this.leftIconClick.emit(e);
    }

    onRightIconClick(e) {
        this.markAsTouched();
        this.rightIconClick.emit(e);
    }

    onClear(e) {
        this.erased.emit(e);
        this.ngControl.control.setValue(this.emptyValue);
    }

    blurField(e) {
        this.isFocused = false;
        this.blured.emit(e);
    }

    focusField(e) {
        this.markAsTouched();
        this.isFocused = true;

        this.focused.emit(e);
    }
}
