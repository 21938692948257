<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            required: required$ | async,
            optionList: options$ | async,
            values: values.$ | async,
            optionClass: multiple || forceCheckbox ? 'bazis-checkbox' : 'bazis-radio',
            disabled: disabled.$ | async
        } as data"
    >
        <div
            class="bazis-control"
            *ngIf="ngControl.control; let control"
        >
            <label
                *ngIf="titleKey || title"
                class="bazis-control__label"
            >
                <bazis-interrogation
                    *ngIf="tooltipSettings?.targetSide === 'labelStart' && tooltipKey"
                    iconSize="xs"
                    [tooltipText]="t(tooltipKey, tooltipParams)"
                    [tooltipOffset]="[-8, 4]"
                    [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                    [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                ></bazis-interrogation>
                {{ title || t(titleKey, titleParams) }}
                <span
                    color="danger"
                    *ngIf="data.required"
                >
                    *
                </span>
                <bazis-interrogation
                    *ngIf="tooltipSettings?.targetSide === 'labelEnd' && tooltipKey"
                    iconSize="xs"
                    [tooltipText]="t(tooltipKey, tooltipParams)"
                    [tooltipOffset]="[-8, 4]"
                    [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                    [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                ></bazis-interrogation>
            </label>

            <label
                class="bazis-control__label"
                *ngIf="isEmptyLabel"
                >&nbsp;</label
            >

            <div [ngClass]="[isOneline ? 'bh-flex bh-align-items-center' : '']">
                <div
                    *ngFor="let option of data.optionList; let i = index"
                    [ngClass]="[
                        data.optionClass,
                        direction === 'right' ? data.optionClass + '_right' : ''
                    ]"
                    [class.bh-inline-block]="isOneline"
                >
                    <label (click)="toggleValue(option.id)">
                        <input
                            [type]="multiple || forceCheckbox ? 'checkbox' : 'radio'"
                            [disabled]="data.disabled"
                            [checked]="data.values.indexOf(option.id) > -1"
                            (change)="preventDefault($event)"
                            (click)="preventDefault($event)"
                        />
                        <span
                            [ngClass]="[
                                data.optionClass + '__label',
                                hasLabelMajor ? 'bh-font-medium' : '',
                                option.$snapshot.name || option.$snapshot.nameKey
                                    ? ''
                                    : 'bazis-label-hidden'
                            ]"
                        >
                            <bazis-icon
                                *ngIf="option.$snapshot.icon"
                                [icon]="option.$snapshot.icon"
                                size="xs"
                            ></bazis-icon>
                            <ng-container *ngIf="option.$snapshot.name">
                                {{ option.$snapshot.name }}
                            </ng-container>
                            <ng-container *ngIf="option.$snapshot.nameKey">
                                {{ t(option.$snapshot.nameKey, option.$snapshot.nameParams) }}
                            </ng-container>
                            <span
                                color="danger"
                                *ngIf="option.$snapshot.required"
                            >
                                *
                            </span>
                        </span>
                    </label>
                </div>

                <!-- <ng-container *ngIf="tooltipKey">
                    <bazis-interrogation
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                        [tooltipPlacement]="tooltipSettings?.placement || 'bottom-start'"
                        [tooltipIsStatic]="false"
                    ></bazis-interrogation>
                </ng-container> -->
            </div>
        </div>
    </ng-container>
</ng-container>
